import { createRouter, createWebHistory } from 'vue-router'
import { IsUserInAnyRole } from '@/services/role.service'
import store from '@/store/index.js'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/HomeView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/client',
        props: true,
        name: 'BecomeClient',
        component: () => import('@/views/BecomeClientView.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('@/views/AuthView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/me',
        name: 'Me',
        component: () => import('@/views/MeView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('@/views/AdminView.vue'),
        meta: {
            requiresAuth: true,
            availableRoles: ["Admin"]
        }
    },
    {
        path: '/card/:cardId',
        props: true,
        name: 'Card',
        component: () => import('@/views/CardView.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reports',
        props: true,
        name: 'Reports',
        component: () => import('@/views/ReportsView.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/contracts',
        props: true,
        name: 'Contracts',
        component: () => import('@/views/Contract/ContractView.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/contracts/payment',
        name: 'Payment',
        component: () => import('@/views/Contract/PaymentView.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/contacts',
        props: true,
        name: 'Contacts',
        component: () => import('@/views/ContactView.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/faq',
        props: true,
        name: 'Faq',
        component: () => import('@/views/FaqView.vue'),
        meta: {
            requiresAuth: true,
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
})

router.beforeEach((to, from, next) => {
    const auth = store.getters['auth/auth']
    const isAuthenticated = auth.isAuthenticated
    const userRoles = auth.roles

    if (to.matched.some(record => record.meta)) {
        if (!!to.meta.requiresAuth && !isAuthenticated) {
            next({ name: 'Auth', replace: true })
        }
        else if (!!!to.meta.requiresAuth) {
            next()
        }
        else { // проверка ролей
            if (!!to.meta.availableRoles && to.meta.availableRoles?.length > 0) {
                if (IsUserInAnyRole(userRoles, to.meta.availableRoles ?? [])) {
                    next()
                    return
                }
                else {
                    next(from)
                    return
                }
            }
            else next() // если страница без ролей
        }
    }
    else next()
})

export default router
