import { IAzs } from '@/models/responses/azs';
import axios from '@/services/axios'

const state = () => ({
    stations: []
})
const getters = {
    stations(ctx: any) : IAzs[] | null {
        return ctx.stations as IAzs[] | null;
    }
}
const actions = {
    async LoadStationsAsync(ctx: any) {
        const response = await axios.get<IAzs[] | null>(`${ctx.rootGetters.baseApiUrl}Azs/All`, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        })
        response.data?.push(<IAzs>{
            code: -9,
            name: 'Личный кабинет',
        })
        if(response.status == 200)
            ctx.state.stations = response.data
        return;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}