import { createStore } from 'vuex'
import auth from '@/store/modules/authorization.module'
import password from '@/store/modules/password.module'
import user from '@/store/modules/user.module'
import card from '@/store/modules/card.module'
import cardOwner from '@/store/modules/card.owner.module'
import role from '@/store/modules/role.module'
import good from '@/store/modules/good.module'
import turn from '@/store/modules/turn.module'
import azs from '@/store/modules/azs.module'
import report from '@/store/modules/report.module'
import contract from '@/store/modules/contract.module'
import balance from '@/store/modules/balance.module'
import becomeClient from '@/store/modules/becomeClient.module'
import limit from '@/store/modules/limit.module'
import payment from '@/store/modules/payment.module'
import version from '@/store/modules/version.module'

import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()

export default createStore({
    state: {
        theme: null,
        baseApiUrl: String(process.env.VUE_APP_BASE_URL),
        isSideMenuOpen: false
    },
    getters: {
        theme: (ctx) => ctx.theme,
        baseApiUrl: (ctx) => ctx.baseApiUrl,
        isSideMenuOpen: (ctx) => ctx.isSideMenuOpen,
    },
    mutations: {
        theme: (ctx, theme) => {
            ctx.theme = theme
            cookies.set('theme', theme)
        },
        isSideMenuOpen: (ctx, isSideMenuOpen) => {
            ctx.isSideMenuOpen = isSideMenuOpen
        }
    },
    actions: {
        LoadThemeFromCookies: (ctx) => {
            if (!ctx.theme || ctx.theme == '') {
                let cookiesTheme = cookies.get('theme')
                if (cookiesTheme && cookiesTheme !== '')
                    ctx.commit('theme', cookiesTheme)
                else
                    ctx.commit('theme', 'light')
            }
        }
    },
    modules: {
        auth,
        password,
        user,
        card,
        cardOwner,
        role,
        good,
        turn,
        azs,
        report,
        contract,
        balance,
        becomeClient,
        limit,
        payment,
        version
    }
})
