import axios from '@/services/axios'
import { CardTurn } from '@/models/responses/turn'

const actions = {
    async GetTurnsByCardAsync(ctx: any, {
        cardId, ownerId = null, from = null, to = null
    }: any) : Promise<CardTurn[] | null> {
        return (await axios.get<CardTurn[] | null>(`${ctx.rootGetters.baseApiUrl}Turn/ByCard/${cardId}`, {
            params: {
                ownerId, from, to
            },
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    },
    async GetTurnsByOwnerAsync(ctx: any, {
        ownerId, cardId = null, from = null, to = null
    }: any) : Promise<CardTurn[] | null> {
        return (await axios.get<CardTurn[] | null>(`${ctx.rootGetters.baseApiUrl}Turn/ByOwner/${ownerId}`, {
            params: {
                cardId, from, to
            },
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    }
}

export default {
    namespaced: true,
    actions
}