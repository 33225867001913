<template>
	<q-btn
		:color="theme ? 'orange' : 'primary'"
		:icon="theme ? 'mdi-white-balance-sunny' : 'mdi-weather-night'"
		@click="theme = !theme"
	/>
</template>

<script setup>
import { useQuasar } from 'quasar'
import { ref, computed } from 'vue'
const { useStore } = require('vuex')

const store = useStore()
const $q = useQuasar()
const cookieTheme = computed(() => store.getters.theme)

const theme = computed({
	get: () => cookieTheme.value == 'light',
	set: (value) => {
		store.commit('theme', value ? 'light' : 'dark')
		$q.dark.set(!value)
	},
})

$q.dark.set(!theme.value)
</script>

<style lang="scss" scoped></style>
