import script from "./MainMenu.vue?vue&type=script&setup=true&lang=js"
export * from "./MainMenu.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QBtn});
