import axios from '@/services/axios'
import Notify from 'quasar/src/plugins/Notify.js';;

const actions = {
    async RegisterPaymentAsync(ctx, {
        cardOwnerId, contractCode, amount, comment
    }) {
        const response = await axios.post(`${ctx.rootGetters.baseApiUrl}payment/register`, {
            cardOwnerId, contractCode, amount, comment
        }, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        })
        return response
    },
    async GetAllPaymentsAsync(ctx, { cardOwnerId, contractCode, page }) {
        const response = await axios.get(`${ctx.rootGetters.baseApiUrl}payment/pagination/${page}`, {
            params: {
                cardOwnerId, contractCode
            },
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        })
        return response.data
    },
    async GetPaymentStatsAsync(ctx) {
        const response = await axios.get(`${ctx.rootGetters.baseApiUrl}payment/stats`, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        })
        return response.data
    },
    async DownloadPaymentsReportAsync(ctx, {from, to}) {
        const res = await axios.get(`${ctx.rootGetters.baseApiUrl}payment/Report/Download`, {
            params: {
                from, to
            },
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            },
            responseType: 'blob'
        })

        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', `Отчет эквайринг сбербанка.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Notify.create({
            color: 'green',
            message: 'Файл успешно загружен',
            timeout: 5000,
            progress: true
        })
    }
}

export default {
    namespaced: true,
    actions
}