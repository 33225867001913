import axios from '@/services/axios'

const namespaced = true;

const state = () => ({
    fuelTypes: []
})

const getters = {
    fuelTypes: (ctx) => {
        return ctx.fuelTypes
    }
}

const actions = {
    async LoadFuelTypesAsync(ctx) {
        ctx.state.fuelTypes = (await axios.get(`${ctx.rootGetters.baseApiUrl}Good/FuelTypes`, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    }
}

export default {
    namespaced,
    state,
    getters,
    actions
}