import Notify from 'quasar/src/plugins/Notify.js';
import axios from '@/services/axios'

const actions = {
    async GetUserContractsAsync(ctx, ownerId) {
        return (await axios.get(`${ctx.rootGetters.baseApiUrl}Contract/All/${ownerId}`, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    }
}

export default {
    namespaced: true,
    actions
}
