<template>
  <VersionBadge />
	<q-layout view="hHh LpR lfr">
		<MainHeader v-if="auth.isAuthenticated" />
		<q-page-container>
			<q-page padding class="container">
				<router-view />
			</q-page>
			<q-page-scroller
				position="bottom-right"
				:scroll-offset="150"
				:offset="[18, 18]"
			>
				<q-btn fab icon="keyboard_arrow_up" color="primary" />
			</q-page-scroller>
		</q-page-container>
	</q-layout>
</template>

<script setup>
import { IsUserInAnyRole } from '@/services/role.service'
import { computed, ref, inject, nextTick, watch, onMounted } from 'vue'
import MainHeader from '@/components/Header/MainHeader.vue'
import VersionBadge from "@/components/UI/VersionBadge";

const { useStore } = require('vuex')
const bus = inject('bus')
const store = useStore()

onMounted(() => {
	store.dispatch('auth/LoadAuthFromCookies')
})

store.dispatch('LoadThemeFromCookies')

const theme = computed(() => store.getters.theme)
const auth = computed(() => store.getters['auth/auth'])

const isAdminOrSuperManager = computed(() => {
	return IsUserInAnyRole(auth.value?.roles, ['Admin', 'SuperManager'])
})

watch(auth, async (n) => {
	if (!!n.isAuthenticated) {
		await store.dispatch('good/LoadFuelTypesAsync')
		setTimeout(
			async () => await store.dispatch('azs/LoadStationsAsync'),
			250
		)
	}
	// if (!!n.isAuthenticated && !!isAdminOrSuperManager.value) {
	// }
})
</script>
