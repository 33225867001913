import axios from '@/services/axios'
import Cookies from 'quasar/src/plugins/Cookies.js';

const actions = {
    async GetCurrentVersionAsync(ctx) {
        const response = await axios.get(ctx.rootGetters.baseApiUrl + 'Version')
        return response.data
    },
    GetCookieVersion() {
        return Cookies.get('version') ?? 0;
    },
    SetCookieVersion(ctx, version) {
        return Cookies.set('version', version);
    }
}

export default {
    namespaced: true,
    actions
}