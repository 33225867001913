import axios from 'axios'
import store from '@/store/index'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer '
    },
    timeout: 0
})

createAuthRefreshInterceptor(instance, async (failedRequest) => {
    let isTokenRefreshed, error;
    try {
        isTokenRefreshed = await store.dispatch('auth/TryRefreshRokens')
    } catch (e) {
        error = e
        return Promise.reject(e)
    }
    if (isTokenRefreshed === false) {
        store.dispatch('auth/Logout')
        return Promise.reject(error)
    }

    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + store.getters['auth/auth'].accessToken
    return Promise.resolve();
});

export default instance