import Notify from 'quasar/src/plugins/Notify.js';
import { CardTurnReportRequest } from '@/models/requests/report'
import axios from '@/services/axios'

const actions = {
	async DownloadCartTurnsReportAsync(ctx, request) {
		const res = await axios.get(`${ctx.rootGetters.baseApiUrl}Report/Download/CardTurn`, {
			params: request,
			headers: {
				'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
			},
			responseType: 'blob'
		})
		let url = window.URL.createObjectURL(new Blob([res.data]));
		let link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.setAttribute('download', `Топливные карты. Отчет.xlsx`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		Notify.create({
			color: 'green',
			message: 'Файл успешно загружен',
			timeout: 5000,
			progress: true
		})
	},
	async DownloadCartTurnsSummaryReportAsync(ctx, request) {
		const res = await axios.get(`${ctx.rootGetters.baseApiUrl}Report/Download/CardTurnSum`, {
			params: request,
			headers: {
				'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
			},
			responseType: 'blob'
		})

		let url = window.URL.createObjectURL(new Blob([res.data]));
		let link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.setAttribute('download', `Топливные карты. Суммарный отчет.xlsx`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		Notify.create({
			color: 'green',
			message: 'Файл успешно загружен',
			timeout: 5000,
			progress: true
		})
	}
}

export default {
	namespaced: true,
	actions
}
