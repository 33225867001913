import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import '@quasar/extras/mdi-v6/mdi-v6.css'
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import langRu from 'quasar/lang/ru'

// To be used on app.use(Quasar, { ... })
export default {
    lang: langRu,
    plugins: {
        Notify, Dialog
    },
    config: {
        brand: {
            primary: '#5c5cff',
            secondary: '#74a2fc',
            accent: '#a061ff',
            dark: '#454545',
            'dark-page': '#303030',
            positive: '#3abd75',
            negative: '#cc2d3f',
            info: '#64d1ca',
            warning: '#fcc221'
        },
        notify: {}
    },

}