<template>
	<q-header reveal class="transparent-header" height-hint="98">
		<q-toolbar>
			<q-toolbar-title class="flex items-center">
				<q-btn
					label="Меню"
					dense
					flat
					icon="menu"
					@click="ToggleLeftDrawer"
					class="q-mr-sm q-mr-md-lg"
				/>
				<div class="desktop-only">
					<router-link to="/">
						<q-avatar>
							<q-img
								src="@/assets/logos/logo-light.svg"
								style="height: 24px; width: 24px"
							/>
						</q-avatar>
					</router-link>
					<q-badge outline color="amber-3" label="Кабинет" />
				</div>
			</q-toolbar-title>
			<div>
				<ThemeSwitcher class="q-mr-md" />
				<q-btn @click="Logout" color="red">
					<q-icon name="mdi-door-open" />
					<q-tooltip class="text-body2">Выйти</q-tooltip>
				</q-btn>
			</div>
		</q-toolbar>
	</q-header>
	<q-drawer v-model="leftDrawerOpen" side="left" elevated bordered>
		<div class="column align-center q-pt-md">
			<MainMenu />
		</div>
	</q-drawer>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ThemeSwitcher from './ThemeSwitcher.vue'
import MainMenu from '../Menus/MainMenu.vue'

const router = useRouter()
const store = useStore()
const leftDrawerOpen = ref(false)
const ToggleLeftDrawer = () => {
	leftDrawerOpen.value = !leftDrawerOpen.value
}

const Logout = () => {
	store.dispatch('auth/Logout')
}
</script>

<style lang="sass" scoped>
.transparent-header
    background-color: #0000007d !important
    -webkit-backdrop-filter: blur(6px) !important
    backdrop-filter: blur(6px) !important
</style>
