import axios from '@/services/axios'

const actions = {
    async CreateRequestAsync(ctx, request) {
        let response = await axios.post(
            `${ctx.rootGetters.baseApiUrl}BecomeClientRequest/Create`,
            request)
        return response
    },
    async PaginationAsync(ctx, {
        skip = 0, take = 10, search = null
    }) {
        let response = await axios.get(
            `${ctx.rootGetters.baseApiUrl}BecomeClientRequest/Pagination`,
            {
                params: {
                    skip, take, search
                }
            })
        return response.data
    }
}

export default {
    namespaced: true,
    actions
}
