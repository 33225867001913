<template>
	<q-list>
		<q-item v-if="isAdmin">
			<q-btn
				align="between"
				to="/admin"
				class="q-mb-sm text-orange-10 full-width"
				icon-right="mdi-cog"
				label="Админка"
				:flat="route.path !== '/admin'"
			/>
		</q-item>
		<q-item>
			<q-btn
				align="between"
				to="/"
				label="Главная"
				class="q-mb-sm full-width"
				icon-right="mdi-home"
				:flat="route.path !== '/'"
			/>
		</q-item>
		<q-item>
			<q-btn
				align="between"
				to="/me"
				label="Профиль"
				class="q-mb-sm full-width"
				icon-right="mdi-account-circle-outline"
				:flat="route.path !== '/me'"
			/>
		</q-item>
		<q-item>
			<q-btn
				align="between"
				to="/reports"
				class="q-mb-sm full-width"
				icon-right="mdi-microsoft-excel"
				label="Отчеты"
				:flat="route.path !== '/reports'"
			/>
		</q-item>
		<q-item>
			<q-btn
				align="between"
				to="/contracts"
				class="q-mb-sm full-width"
				icon-right="mdi-cash-multiple"
				label="Балансы"
				:flat="route.path !== '/contracts'"
			/>
		</q-item>
		<q-item>
			<q-btn
				align="between"
				to="/contacts"
				class="q-mb-sm full-width"
				icon-right="mdi-contacts"
				label="Контакты"
				:flat="route.path !== '/contacts'"
			/>
		</q-item>
		<q-item>
			<q-btn
				align="between"
				to="/faq"
				class="q-mb-sm full-width"
				icon-right="mdi-lightbulb-on"
				label="Частые вопросы"
				:flat="route.path !== '/faq'"
			/>
		</q-item>
		<q-item>
			<q-btn
				align="between"
				@click="Logout"
				color="red"
				label="Выйти"
				icon-right="mdi-door-open"
				class="full-width"
				flat
			/>
		</q-item>
	</q-list>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { IsUserInRole } from '@/services/role.service'

const store = useStore()
const route = useRoute()

const isAdmin = computed(() =>
	IsUserInRole(store.getters['auth/auth'].roles, 'Admin')
)

const Logout = () => {
	store.dispatch('auth/Logout')
}
</script>
