import axios from '@/services/axios'

const namespaced = true

const actions = {
    async GetByIdAsync(ctx, id) {
        const response = await axios.get(ctx.rootGetters.baseApiUrl + 'User/' + id, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        })
        return response.data
    },
    async PaginationAsync(ctx, {
        search = '',
        skip = 0,
        take = 10
    }) {
        const response = await axios.get(`${ctx.rootGetters.baseApiUrl}User/Pagination`,
            {
                params: {
                    search, skip, take
                },
                headers: {
                    'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
                }
            })
        return response.data
    },
    async PutAsync(ctx, {
        id, displayName, tel, email, defaultServioUserId,
        roleIds = null, servioUserIds = null
    }) {
        const response = await axios.put(`${ctx.rootGetters.baseApiUrl}User`, {
            id, displayName, tel, email, defaultServioUserId, roleIds, servioUserIds
        },
            {
                headers: {
                    'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
                }
            })
        return response
    },
    async PostAsync(ctx, {
        login, displayName, tel, email, password, defaultServioUserId,
        roleIds = null, servioUserIds = null
    }) {
        const response = await axios.post(`${ctx.rootGetters.baseApiUrl}User`, {
            login, displayName, tel, email, password,
            defaultServioUserId, roleIds, servioUserIds
        },
            {
                headers: {
                    'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
                }
            })
        return response
    },
    async DeleteAsync(ctx, id) {
        const response = await axios.delete(`${ctx.rootGetters.baseApiUrl}User/${id}`, {},
            {
                headers: {
                    'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
                }
            })
        return response
    },
    async IsUserExists(ctx, identity) {
        if (!identity) return false
        let response
        try {
            response = await axios.get(ctx.rootState.baseApiUrl + 'User/Exists/' + identity)
        } catch {
            return false
        }
        return response.status >= 200 && response.status < 300
    },
    async ChangeDisabledStatusAsync(ctx, { userId, isDisabled }) { //returns isSuccess:bool
        let response;
        try {
            response = await axios.put(ctx.rootState.baseApiUrl + 'User/Status/' + userId + '/' + isDisabled)
        } catch {
            return false
        }
        return response.status >= 200 && response.status < 300
    }
}

export default {
    namespaced,
    actions
}