
export const IsUserInAnyRole = (userRoles, roles) => {
    if (!!!roles) return false
    if (!!!userRoles) return false

    for (let role of roles) {
        if (userRoles.indexOf(role) >= 0) return true
    }
    return false
}

export const IsUserInRole = (userRoles, role) => {
    if (!!!role) return false
    if (!!!userRoles) return false

    if (userRoles.indexOf(role) !== -1) return true
    return false
}