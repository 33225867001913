import axios from '@/services/axios'
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()

const state = () => ({
    lastSelectedId: null
})

const getters = {
    lastSelectedId: (ctx) => {
        if (ctx.lastSelectedId !== null) return ctx.lastSelectedId
        const cookieValue = cookies.get('cardOwner:lastSelectedId')
        if (cookieValue !== null) {
            ctx.lastSelectedId = Number(cookieValue)
            return ctx.lastSelectedId
        }
        else return null
    }
}

const mutations = {
    lastSelectedId: (ctx, lastSelectedId) => {
        ctx.lastSelectedId = Number(lastSelectedId)
        cookies.set('cardOwner:lastSelectedId', lastSelectedId)
    }
}

const actions = {
    PaginationAsync: async (ctx, { search = '', skip = 0, take = 10 }) => {
        return (await axios.get(`${ctx.rootGetters.baseApiUrl}CardOwner/Pagination`, {
            params: {
                search, skip, take
            },
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    },
    SlimAsync: async (ctx) => {
        return (await axios.get(`${ctx.rootGetters.baseApiUrl}CardOwner/Slim`, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}