import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
import axios from '@/services/axios'
import router from '@/router'
import { ParseJwt } from "@/services/jwt"

const namespaced = true

const state = () => ({
    auth: {
        isAuthenticated: false,
        accessToken: '',
        refreshToken: '',
        userId: null,
        login: '',
        displayName: '',
        defaultServioUserLogin: null,
        defaultServioUserId: null,
        roles: []
    }
})

const getters = {
    auth: (ctx) => ctx.auth,
}

const mutations = {
    auth: (ctx, auth) => {
        ctx.auth = auth
        cookies.set('auth', auth)
    }
}

const actions = {
    Authenticate: async (ctx, { identity, password }) => {
        let response = null
        try {
            response = await axios(ctx.rootState.baseApiUrl + 'Authorization/Authenticate', {
                method: 'POST',
                data: {
                    identity, password
                }
            })
        } catch (e) {
            return e
        }

        if (response.data?.accessToken?.length > 0) {
            var jwtData = ParseJwt(response.data?.accessToken)
            const auth = {
                isAuthenticated: true,
                accessToken: response.data?.accessToken,
                refreshToken: response.data?.refreshToken,
                login: response.data?.login,
                displayName: response.data?.displayName,
                userId: jwtData['User:Id'],
                defaultServioUserLogin: jwtData['User:DefaultServioUserLogin'],
                defaultServioUserId: jwtData['User:DefaultServioUserId'],
                roles: jwtData['User:Roles']
            }
            ctx.commit('auth', auth)
        }
        else {
            ctx.dispatch('ResetAuth')
        }

        return response
    },
    async TryRefreshRokens(ctx) {
        let response = null
        try {
            response = await axios(ctx.rootState.baseApiUrl + 'Authorization/Refresh/' + ctx.getters.auth.refreshToken, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${ctx.getters.auth?.accessToken}`
                }
            })
        } catch (e) {
            ctx.dispatch('ResetAuth')
            return false
        }

        if (response.data?.accessToken?.length > 0) {
            var jwtData = ParseJwt(response.data?.accessToken)
            let auth = {
                isAuthenticated: true,
                accessToken: response.data?.accessToken,
                refreshToken: response.data?.refreshToken,
                login: response.data?.login,
                displayName: response.data?.displayName,
                userId: jwtData['User:Id'],
                defaultServioUserLogin: jwtData['User:DefaultServioUserLogin'],
                defaultServioUserId: jwtData['User:DefaultServioUserId'],
                roles: jwtData['User:Roles']
            }
            ctx.commit('auth', auth)
            return true
        }
        else {
            ctx.dispatch('ResetAuth')
            return false
        }
    },
    IsUserInRole(ctx, role) {
        return ctx.getters.auth?.roles?.includes(role)
    },
    IsUserInAnyRole(ctx, roles) {
        if (!!!roles) return false
        const stateRoles = ctx.getters.auth?.roles
        if (!!!stateRoles) return false

        for (let role of roles) {
            if (stateRoles.indexOf(role) >= 0) return true
        }
        return false
    },
    Logout(ctx) {
        ctx.dispatch('ResetAuth')
        router.push('/auth')
    },
    ResetAuth(ctx) {
        let auth = {
            isAuthenticated: false
        }
        ctx.commit('auth', auth)
        cookies.remove('auth')
    },
    LoadAuthFromCookies(ctx) {
        const auth = cookies.get('auth')
        if (!!auth && !!auth.isAuthenticated === true)
            ctx.commit('auth', auth)
        //else router.push('/auth')
    }
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}