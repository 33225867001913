import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
import axios from '@/services/axios'

const state = () => ({
    lastSelectedId: null
})

const getters = {
    lastSelectedId: (ctx) => {
        if (ctx.lastSelectedId !== null) return ctx.lastSelectedId
        const cookieValue = cookies.get('card:lastSelectedId')
        if (cookieValue !== null) {
            ctx.lastSelectedId = cookieValue
            return ctx.lastSelectedId
        }
        else return null
    }
}

const mutations = {
    lastSelectedId: (ctx, lastSelectedId) => {
        ctx.lastSelectedId = lastSelectedId
        cookies.set('card:lastSelectedId', lastSelectedId)
    }
}

const actions = {
    async GetCardAsync(ctx, cardId) {
        return (await axios.get(`${ctx.rootGetters.baseApiUrl}Card/${cardId}`, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    },
    async GetOwnerCardsAsync(ctx, ownerId) {
        return (await axios.get(`${ctx.rootGetters.baseApiUrl}Card/ByOwner/${ownerId}`, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    },
    async GetSlimOwnerCardsAsync(ctx, ownerId) {
        return (await axios.get(`${ctx.rootGetters.baseApiUrl}Card/ByOwner/Slim/${ownerId}`, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    },
    async ChangeCardNoteAsync(ctx, { cardId, note }) {
        let status = null;
        await axios.put(`${ctx.rootGetters.baseApiUrl}Card/Note/${cardId}`, note, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        })
            .then(r => status = r.status)
            .catch(e => status = e.response.status)
        return status
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}