import axios from '@/services/axios'

const actions = {
    AllAsync: async (ctx) => {
        return (await axios.get(`${ctx.rootGetters.baseApiUrl}Role/All`, {
            headers: {
                'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
            }
        }))?.data
    }
}

export default {
    namespaced: true,
    actions
}