import axios from '@/services/axios'

const actions = {
    async IncreaseCardBalanceAsync(ctx, {
        ownerId, cardId, issuerId, productId, volume
    }) {
        let response = await axios.post(`${ctx.rootGetters.baseApiUrl}Balance/Card/Increase`,
            { ownerId, cardId, issuerId, productId, volume },
            {
                headers: {
                    'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
                }
            })
        return response
    }
}

export default {
    namespaced: true,
    actions
}
