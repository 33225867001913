import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueCookies from 'vue3-cookies'

// quasar
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import 'quasar/dist/quasar.addon.css'

// custom styles 
import '@/styles/containers.sass'

import mitt from 'mitt'
const bus = mitt()

const app = createApp(App)

app.config.globalProperties.$bus = bus

app
    .use(Quasar, quasarUserOptions)
    .use(router)
    .use(store)
    .use(VueCookies, {
        expireTimes: "365d",
        path: "/",
    })

app.provide('bus', bus)

app.mount('#app')
