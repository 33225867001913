import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
import axios from '@/services/axios'

const namespaced = true

const actions = {
    async SendPasswordRestoreCode(ctx, identity) {
        let response = null
        try {
            response = await axios.post(`${ctx.rootState.baseApiUrl}Password/SendPasswordRestoreCode`, {
                identity
            })
        }
        catch (e) {
            return e.response.status ?? 500
        }
        return response.status
    },
    async IsRestoreCodeValid(ctx, { identity, code }) {
        let response = null
        try {
            response = await axios.get(`${ctx.rootState.baseApiUrl}Password/IsRestoreCodeValid`, {
                params: {
                    identity, code
                }
            })
        } catch (e) {
            return e.response.status ?? 500
        }
        return response.status
    },
    async ChangePasswordByCode(ctx, {
        identity, code, newPassword, newPasswordConfirm
    }) {
        let response = null
        try {
            response = await axios({
                url: `${ctx.rootState.baseApiUrl}Password/ChangeByCode`,
                method: 'POST',
                data: {
                    identity: identity,
                    code: String(code),
                    newPassword: String(newPassword),
                    newPasswordConfirm: String(newPasswordConfirm)
                }
            })
        } catch (e) {
            return e.response.status ?? 500
        }
        return response.status
    },
    async ChangePassword(ctx, {
        identity, oldPassword, newPassword, newPasswordConfirm
    }) {
        let response = null
        try {
            response = await axios.post(`${ctx.rootState.baseApiUrl}Password/Change`, {
                identity, oldPassword, newPassword, newPasswordConfirm
            }, {
                headers: { 'Authorization': `Bearer ${ctx.getters.auth?.accessToken}` }
            })
        } catch (e) {
            return e.response.status ?? 500
        }
        return response.status
    },
    async ChangePasswordByAdmin(ctx, {
        password, userId
    }) {
        let response = null
        try {
            response = await axios.post(`${ctx.rootState.baseApiUrl}Password/Change/ByAdmin/${userId}`,
                password, {
                headers: { 'Authorization': `Bearer ${ctx.getters.auth?.accessToken}` }
            })
        } catch (e) {
            return false
        }
        return true
    },
}

export default {
    namespaced,
    actions
}