<template>
  <div></div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount} from 'vue'
import {useStore} from "vuex";

const store = useStore();
const serverVersion = ref(0)

let pollingInterval = setInterval(async () => {
  await loadVersionAsync();
}, 30000)

async function loadVersionAsync() {
  serverVersion.value = await store.dispatch('version/GetCurrentVersionAsync');
  const cookieVersion = await store.dispatch('version/GetCookieVersion')
  if(serverVersion.value > cookieVersion) {
    await store.dispatch('version/SetCookieVersion', serverVersion.value)
    window.location.reload(true);
  }
}

onMounted(() => {
  loadVersionAsync();
})
onBeforeUnmount(() => {
  clearInterval(pollingInterval)
})
</script>