import axios from '@/services/axios'

const namespaced = true;
const actions = {
    async SetDayLimitAsync(ctx, {
        cardId, issuerId, productId, volume
    }) {
        let response;
        try {
            response = ctx.state.fuelTypes = await axios.post(`${ctx.rootGetters.baseApiUrl}Limit/Set/Daylimit`, {
                cardId, issuerId, productId, volume
            }, {
                headers: {
                    'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
                }
            })
        } catch (e) {
            return false;
        }
        return response.status === 200
    },
    async SetMonthLimitAsync(ctx, {
        cardId, issuerId, productId, volume
    }) {
        let response;
        try {
            response = await axios.post(`${ctx.rootGetters.baseApiUrl}Limit/Set/Monthlimit`, {
                cardId, issuerId, productId, volume
            }, {
                headers: {
                    'Authorization': `Bearer ${ctx.rootState.auth['auth'].accessToken}`
                }
            })
        } catch (e) {
            return false;
        }
        return response.status === 200
    }
}

export default {
    namespaced,
    actions
}